import {
  AppstoreFilled,
  SettingFilled,
  ShoppingFilled,
  SmileFilled,
  TagFilled,
  FileTextFilled,
  NotificationFilled,
  ReadFilled,
} from '@ant-design/icons';

import { AppMenu } from '@libs/dto';

import { tenant } from './routes.config';

// import { cmsMenus } from './module/cms/menus';
// import { roomMenus } from './module/room/menus';
// import { ecommerceMenus } from './module/ecommerce/menus';
// import { foodMenus } from './module/food/menus';
// import { souvenirMenus } from './module/souvenir/menus';

export const tenantMenus: AppMenu = [
  {
    name: 'Dashboard',
    title: 'Dashboard',
    linkTo: `${tenant}/ecommerce/dashboard`,
    Icon: AppstoreFilled,
    key: '1',
  },
  {
    name: 'Orders',
    title: 'Orders',
    linkTo: `${tenant}/ecommerce/orders`,
    Icon: ShoppingFilled,
    key: '2',
  },
  {
    name: 'Products',
    title: 'Products',
    linkTo: `${tenant}/ecommerce/products`,
    Icon: TagFilled,
    key: '3',
    isParent: true,
    subMenu: [
      {
        title: 'All Products',
        linkTo: `${tenant}/ecommerce/products`,
        subKey: '3.1',
      },
      {
        title: 'Inventory',
        linkTo: `${tenant}/ecommerce/inventory`,
        subKey: '3.2',
      },
      {
        title: 'Category',
        linkTo: `${tenant}/ecommerce/products/categories`,
        subKey: '3.3',
      },
      {
        title: 'Registration',
        linkTo: `${tenant}/ecommerce/products-registration`,
        subKey: '3.4',
      },
    ],
  },
  {
    name: 'Customers',
    title: 'Customers',
    linkTo: `${tenant}/ecommerce/customers`,
    Icon: SmileFilled,
    key: '4',
  },
  {
    name: 'Reviews',
    title: 'Reviews',
    linkTo: `${tenant}/ecommerce/reviews`,
    Icon: ReadFilled,
    key: '5',
  },
  {
    name: 'CMS',
    title: 'CMS',
    linkTo: `${tenant}/cms`,
    Icon: FileTextFilled,
    key: '6',
    isParent: true,
    subMenu: [
      {
        title: 'Articles',
        linkTo: `${tenant}/cms/articles`,
        subKey: '6.1',
      },
      {
        title: 'Categories',
        linkTo: `${tenant}/cms/categories`,
        subKey: '6.2',
      },
      {
        title: 'Banners',
        linkTo: `${tenant}/cms/banners`,
        subKey: '6.3',
      },
      {
        title: 'Tags',
        linkTo: `${tenant}/cms/tags`,
        subKey: '6.4',
      },
      {
        title: 'Menus',
        linkTo: `${tenant}/cms/menus`,
        subKey: '6.5',
      },
      {
        title: 'Pop Up',
        linkTo: `${tenant}/cms/popup`,
        subKey: '6.6',
      },
      {
        title: 'B2B',
        linkTo: `${tenant}/cms/b2b`,
        subKey: '6.7',
      },
    ],
  },
  {
    name: 'Marketing',
    title: 'Marketing',
    linkTo: `${tenant}/ecommerce/marketing`,
    Icon: NotificationFilled,
    key: '7',
    isParent: true,
    subMenu: [
      {
        title: 'Vouchers',
        linkTo: `${tenant}/ecommerce/marketing/vouchers`,
        subKey: '7.1',
      },
    ],
  },

  // ...roomMenus,
  // ...ecommerceMenus,
  // ...foodMenus,
  // ...souvenirMenus,
  // ...cmsMenus,
  {
    name: 'Setting',
    title: 'Settings',
    linkTo: `${tenant}/settings`,
    Icon: SettingFilled,
    key: '8',
  },
];
