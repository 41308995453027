import { axiosInstance } from '@config/axios-instance';
import { File } from '@libs/dto';
import { images } from '../config';

const url = '/admin/settings/images';

export const imageService = {
  list(page: string) {
    return axiosInstance.get(`${url}?page=${page || 1}&perPage=12`);
  },
  upload(data: File) {
    return axiosInstance.post(`${images}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  delete(id: string) {
    return axiosInstance.delete(`${url}/${id}`);
  },
};

export type ImageService = typeof imageService;
