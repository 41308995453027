import React from 'react';
import { AppRoutes } from '@libs/dto';
import { notification } from './routes.config';
const ConfigureNotificationChannel = React.lazy(() => import('@modules/notification/pages/Channel/Configure'));

export const routes: AppRoutes = [
  {
    id: 1,
    path: `${notification}/notification-channels/:code`,
    component: ConfigureNotificationChannel,
    exact: true,
  },
];
