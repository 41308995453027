import React from 'react';
import { AppRoutes } from '@libs/dto';
import { customer } from './routes.config';

const CustomerList = React.lazy(() => import('@modules/customer/pages/Customers'));
const CustomerAdd = React.lazy(() => import('@modules/customer/pages/Customers/Add'));
const CustomerDetail = React.lazy(() => import('@modules/customer/pages/Customers/Detail'));

export const routes: AppRoutes = [
  {
    id: 29,
    path: `${customer}`,
    component: CustomerList,
    exact: true,
  },
  {
    id: 30,
    path: `${customer}/add`,
    component: CustomerAdd,
    exact: true,
  },
  {
    id: 31,
    path: `${customer}/:customer_id`,
    component: CustomerDetail,
    exact: true,
  },
];
