import { AppRoutes } from '@libs/dto';
import Tenant from '@modules/tenant';
import Branch from '@modules/branch';

import Forbidden from '@pages/Forbidden';
import BadGateway from '@pages/BadGateway';
import NotFound from '@pages/NotFound';
import InternalServer from '@pages/InternalServer';

export const globalRoutes: AppRoutes = [
  {
    id: 1,
    path: '/tenant',
    component: Tenant,
  },
  {
    id: 2,
    path: '/branch',
    component: Branch,
  },
  {
    id: 45,
    path: '/forbidden',
    component: Forbidden,
    exact: true,
  },
  {
    id: 47,
    path: '/bad-gateway',
    component: BadGateway,
    exact: true,
  },
  {
    id: 48,
    path: '/not-found',
    component: NotFound,
    exact: true,
  },
  {
    id: 49,
    path: '/server-error',
    component: InternalServer,
    exact: true,
  },
];
