import React from 'react';
import Sidebar from '@components/Sidebar/Sidebar';
import { Layout, Spin, Row } from 'antd';
import { Switch, Route, withRouter } from 'react-router-dom';
import { routes } from './routes';
import { AppMenu } from '@libs/dto';
import { tenantMenus } from './menus';
// import Header from '@components/Header';
import { Helmet } from 'react-helmet';

const Spinner = () => (
  <Layout style={{ placeContent: 'center', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <Row align="middle" justify="center">
      <Spin size="large" />
    </Row>
  </Layout>
);

function Tenant() {
  const sidebarMenus: AppMenu = tenantMenus;
  const baseSiteName = process.env.REACT_APP_BASE_SITE_NAME || 'Zojirushi Indonesia Alat Rumah Tangga No.1 di Jepang';

  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <Helmet>
          <title>{baseSiteName}</title>
        </Helmet>

        <Sidebar menus={sidebarMenus} />

        <Layout className="site-layout">
          {/* <Header /> */}
          <div style={{ marginLeft: 220 }}>
            <React.Suspense fallback={<Spinner />}>
              <Switch>
                {routes.map((route) => (
                  <Route {...route} key={route.id} />
                ))}
              </Switch>
            </React.Suspense>
          </div>
        </Layout>
      </Layout>
    </>
  );
}

export default withRouter(Tenant);
