import AppstoreFilled from '@ant-design/icons/AppstoreFilled';
import SettingFilled from '@ant-design/icons/SettingFilled';

import { AppMenu } from '@libs/dto';

// import { roomMenus } from './module/room/menus';
import { ecommerceMenus } from './module/ecommerce/menus';
// import { foodMenus } from './module/food/menus';
// import { souvenirMenus } from './module/souvenir/menus';

export const branchMenus: AppMenu = [
  {
    title: 'Dashboard',
    linkTo: `/branch/:id/dashboard`,
    Icon: AppstoreFilled,
    key: '1',
  },
  // ...roomMenus,
  // ...foodMenus,
  // ...souvenirMenus,
  ...ecommerceMenus,
  {
    title: 'Settings',
    linkTo: `/branch/:id/settings`,
    Icon: SettingFilled,
    key: '5',
  },
];
