import React from 'react';
import ContentHelmet from '@components/ContentHelmet';
import './BadGateway.scss';
import BadGateway from '@assets/bad-gateway.jpg';
import { Layout } from 'antd';
import Sidebar from '@components/Sidebar/Sidebar';
import { tenantMenus } from '@modules/tenant/menus';
import { AppMenu } from '@libs/dto';

export default function Forbidden() {
  const sidebarMenus: AppMenu = tenantMenus;
  return (
    <ContentHelmet title="Bad Gateway">
      <Sidebar menus={sidebarMenus} />

      <Layout className="site-layout">
        <div className="container-badgateway">
          <h3
            style={{
              color: '#E83040',
              fontWeight: 'bold',
            }}
          >
            Error 502 Bad Gateway
          </h3>
          <img src={BadGateway} alt="badgateway" className="badgateway" style={{ width: 700 }} />
        </div>
      </Layout>
    </ContentHelmet>
  );
}
