import { MenuInterface } from '@libs/dto';
import ShoppingFilled from '@ant-design/icons/ShoppingFilled';
import { branchEcommerce } from './routes.config';

export interface AppMenu extends Array<MenuInterface> {}

export const ecommerceMenus: AppMenu = [
  {
    title: 'Ecommerce',
    Icon: ShoppingFilled,
    isParent: true,
    key: '3',
    subMenu: [
      {
        title: 'Dashboard',
        linkTo: `${branchEcommerce}/dashboard`,
        subKey: '3.1',
      },
      {
        title: 'Orders',
        linkTo: `${branchEcommerce}/orders`,
        subKey: '3.2',
      },
      {
        title: 'Settings',
        linkTo: `${branchEcommerce}/settings?tab=products`,
        subKey: '3.3',
      },
    ],
  },
];
