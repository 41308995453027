import React from 'react';
import ContentHelmet from '@components/ContentHelmet';
import './Forbidden.scss';
import ForbiddenImage from '@assets/forbidden.jpg';
import { Layout } from 'antd';
import Sidebar from '@components/Sidebar/Sidebar';
import { tenantMenus } from '@modules/tenant/menus';
import { AppMenu } from '@libs/dto';

export default function Forbidden() {
  const sidebarMenus: AppMenu = tenantMenus;
  return (
    <ContentHelmet title="Forbidden Page">
      <Sidebar menus={sidebarMenus} />

      <Layout className="site-layout">
        <div className="container-forbidden">
          <img src={ForbiddenImage} alt="forbidden" className="forbidden" style={{ width: 700 }} />
        </div>
      </Layout>
    </ContentHelmet>
  );
}
