/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AppMenu } from '@libs/dto';
import './Sidebar.scss';
import { useApp } from '@context/app-context';
import defaultWarungIcon from '@assets/defaultWarungIcon.svg';
import { useAuth } from '@context/auth-context';

const { Sider } = Layout;
const { SubMenu } = Menu;
interface SidebarProps {
  menus: AppMenu;
}

const Sidebar: React.FC<SidebarProps> = ({ menus }) => {
  const { selectBranch } = useApp();
  const { tenantProfile } = useAuth();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(location.pathname);

  const history = useHistory();

  const [collapsed, setCollapsed] = useState<boolean>(false);

  const onCollapse = (_collapsed: boolean) => {
    setCollapsed(_collapsed);
  };

  React.useEffect(() => {
    setSelectedKey(location.pathname);
  }, [location.pathname]);

  return (
    <Sider
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        zIndex: 9999,
      }}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      trigger={null}
    >
      {/* <img src={Logo} alt="logo" className="logo" /> */}

      <div style={{ display: 'flex' }}>
        <div style={{ paddingRight: 24, paddingLeft: 20 }}>
          <div className="select-branch">
            <div className="title-tenant">
              <div className="title-tenant-image-and-title">
                {tenantProfile?.images.length ? (
                  <img
                    src={tenantProfile.images[0].url}
                    style={{ width: 25, height: 25, objectFit: 'cover' }}
                    alt={tenantProfile.name}
                  />
                ) : (
                  <img src={defaultWarungIcon} alt="Warung Icon" />
                )}

                <div
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '10ch',
                    fontSize: 15,
                    color: 'white',
                  }}
                >
                  {tenantProfile?.name || 'Warung'}
                </div>
              </div>
              <RightOutlined className="title-text" />
            </div>
          </div>
          <Menu
            theme="dark"
            defaultSelectedKeys={[selectedKey]}
            selectedKeys={[selectedKey]}
            // openKeys={['2']}
            mode="inline"
            forceSubMenuRender
          >
            {menus.map((menu) => {
              if (menu.hidden) {
                return null;
              }
              if (menu.isParent && menu.subMenu) {
                return (
                  <SubMenu
                    onTitleClick={() => console.log(menu.key)}
                    key={`${menu.linkTo}`}
                    icon={menu.Icon && <menu.Icon />}
                    title={menu.title}
                  >
                    {menu.subMenu.map((sub) => {
                      if (sub.hidden) {
                        return null;
                      }
                      return (
                        <Menu.Item key={`${sub.linkTo}`} icon={sub.Icon && <sub.Icon />}>
                          <Link onClick={() => setSelectedKey(`${sub.linkTo}`)} to={sub.linkTo}>
                            {sub.title}
                          </Link>
                        </Menu.Item>
                      );
                    })}
                  </SubMenu>
                );
              }
              return (
                <Menu.Item className="sidebar-menu" key={`${menu.linkTo}`} icon={menu.Icon && <menu.Icon />}>
                  {menu.linkTo ? (
                    <Link onClick={() => setSelectedKey(`${menu.linkTo}`)} to={menu.linkTo}>
                      {menu.title}
                    </Link>
                  ) : (
                    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
                    <a
                      role="button"
                      onClick={() => {
                        if (menu.action) {
                          menu.action();
                        }
                        if (menu.title === 'Super Admin') {
                          selectBranch(null, null);
                          history.push('/tenant/dashboard');
                        }
                      }}
                    >
                      {menu.title}
                    </a>
                  )}
                </Menu.Item>
              );
            })}
          </Menu>
        </div>
      </div>
    </Sider>
  );
};

export default Sidebar;
