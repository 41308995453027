import Axios, { AxiosRequestConfig } from 'axios';
import { useNotif } from '@hooks/use-notif';

export const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const { addError } = useNotif();
axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const getToken = localStorage.getItem('token');
  const getTenantId = localStorage.getItem('tenantId');
  return {
    ...config,
    headers: {
      Authorization: `Bearer ${getToken}`,
      'x-tenant-id': getTenantId,
      ...config.headers,
    },
  };
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log('error123', error);
    // eslint-disable-next-line no-console
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if (error.response.status === 403) {
      // localStorage.clear();
      window.location.href = '/forbidden';
    } else if (error.response.status === 502) {
      // localStorage.clear();
      window.location.href = '/bad-gateway';
    } else if (error.response.status === 500) {
      // localStorage.clear();
      // window.location.href = '/server-error';
    } else if (error.response.status === 404) {
      // localStorage.clear();
      window.location.href = '/not-found';
    } else if (error.response.status === 400) {
    } else if (error.response.status === 422) {
    } else {
      window.location.href = '/forbidden';
    }
    console.log('Error at interceptor  :', { ...error });
    addError(error);
    // eslint-disable-next-line no-alert
    return Promise.reject(error);
  }
);
