import React from 'react';
import { AppRoutes } from '@libs/dto';
import { branchRoom } from './routes.config';

const RoomDashboard = React.lazy(() => import('@modules/room/pages/Dashboard'));
const ListRoomOrder = React.lazy(() => import('@modules/room/pages/Orders/List'));
const RoomOrderDetail = React.lazy(() => import('@modules/room/pages/Orders/Detail'));
const Availability = React.lazy(() => import('@modules/room/pages/Availability'));
const Rate = React.lazy(() => import('@modules/room/pages/Rate'));
const RoomSetting = React.lazy(() => import('@modules/room/pages/Settings'));
const AddRoom = React.lazy(() => import('@modules/room/pages/Settings/AddRoom'));
const DetailRoom = React.lazy(() => import('@modules/room/pages/Settings/DetailRoom'));

export const routes: AppRoutes = [
  {
    id: 1,
    path: `${branchRoom}/dashboard`,
    component: RoomDashboard,
    exact: true,
  },

  {
    id: 4,
    path: `${branchRoom}/orders`,
    component: ListRoomOrder,
    exact: true,
  },

  {
    id: 6,
    path: `${branchRoom}/orders/:order_id`,
    component: RoomOrderDetail,
    exact: true,
  },
  {
    id: 7,
    path: `${branchRoom}/availability`,
    component: Availability,
    exact: true,
  },
  {
    id: 8,
    path: `${branchRoom}/rate`,
    component: Rate,
    exact: true,
  },

  {
    id: 10,
    path: `${branchRoom}/settings`,
    component: RoomSetting,
    exact: true,
  },
  {
    id: 11,
    path: `${branchRoom}/settings/add`,
    component: AddRoom,
    exact: true,
  },
  {
    id: 12,
    path: `${branchRoom}/:room_id/settings`,
    component: DetailRoom,
    exact: true,
  },
];
