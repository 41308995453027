import React from 'react';
import { AppRoutes } from '@libs/dto';

import { room } from './routes.config';

const RoomDashboard = React.lazy(() => import('@modules/room/pages/Dashboard'));
const ListRoomOrder = React.lazy(() => import('@modules/room/pages/Orders/List'));
const RoomOrderDetail = React.lazy(() => import('@modules/room/pages/Orders/Detail'));
const RoomSetting = React.lazy(() => import('@modules/room/pages/Settings'));

export const routes: AppRoutes = [
  {
    id: 2,
    path: `${room}/dashboard`,
    component: RoomDashboard,
    exact: true,
  },
  {
    id: 3,
    path: `${room}/orders`,
    component: ListRoomOrder,
    exact: true,
  },
  {
    id: 5,
    path: `${room}/orders/:order_id`,
    component: RoomOrderDetail,
    exact: true,
  },
  {
    id: 9,
    path: `${room}/settings`,
    component: RoomSetting,
    exact: true,
  },
];
