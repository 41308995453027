/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { UserDto, AdminSignInDto, AdminAndTokenDto, TenantProfileDto, TenantDto } from '@libs/dto/admin';
import { useService } from '@hooks/use-service';
import { AdminService } from '@services/admin';
import { WEB_SERVICES } from '@services/index';
import { useHistory } from 'react-router-dom';
import { Layout, Spin, Row } from 'antd';
import { useNotif } from '@hooks/use-notif';

interface AuthContextState {
  isSubmitted: boolean;
  isLoading: boolean;
  identity: UserDto | null;
  token: string;
  isError: boolean;
  tenantProfile: TenantDto;
}

export interface AuthContextValue {
  identity: UserDto | null;
  signIn(credential: AdminSignInDto): void;
  signOut(): void;
  isLoading: boolean;
  token: string;
  isSubmitted: boolean;
  isError: boolean;
  tenantProfile: TenantDto;
}

const Spinner = () => (
  <Layout style={{ placeContent: 'center', height: '100vh' }}>
    <Row align="middle" justify="center">
      <Spin size="large" />
    </Row>
  </Layout>
);

const AuthContext = React.createContext<AuthContextValue>({} as never);

export default function AuthContextProvider({ children }) {
  const { addError } = useNotif();
  const [{ identity, isLoading, token, isSubmitted, isError, tenantProfile }, updateState] = useState<AuthContextState>(
    {
      isLoading: true,
      identity: null,
      token: localStorage.getItem('selectedBranchId'),
      isSubmitted: false,
      isError: false,
      tenantProfile: JSON.parse(localStorage.getItem('tenantProfile')),
    }
  );
  const adminService = useService<AdminService>(WEB_SERVICES.Admin);

  const history = useHistory();

  const setToken = (_token: string) => {
    updateState((prevState) => ({
      ...prevState,
      token: _token,
    }));
  };

  const setIdentity = (profile: UserDto | null) => {
    updateState((prevState) => ({
      ...prevState,
      identity: profile,
    }));
  };

  const setLoading = (state: boolean) => {
    updateState((prevState) => ({
      ...prevState,
      isLoading: state,
    }));
  };

  const setSubmit = (state: boolean) => {
    updateState((prevState) => ({
      ...prevState,
      isSubmitted: state,
    }));
  };

  const setError = (state: boolean) => {
    updateState((prevState) => ({
      ...prevState,
      isError: state,
    }));
  };

  let data;

  const signIn = (credential: AdminSignInDto) => {
    setSubmit(true);
    adminService
      .query<Promise<AdminAndTokenDto>>('signIn', [credential])
      .then((res) => {
        data = res.data;
        // const data = res.data;
        // eslint-disable-next-line no-underscore-dangle
        const _token = res.data.token;
        localStorage.setItem('token', _token);
        localStorage.setItem('tenantId', data.user.tenant.id);
        localStorage.setItem('uat', JSON.stringify(data.user));
        updateState((previousState) => ({ ...previousState, identity: data.user }));
        setSubmit(false);

        return adminService.query<Promise<TenantProfileDto>>('getTenantProfile');

        // if (admin.branch && admin.branch.role === 'FRONTDESK') {
        //   localStorage.setItem('selectedBranchId', admin.branch.id);
        //   history.push(`/branch/${admin.branch.id}/rooms/orders`);
        // }
        // if (admin.branch && admin.branch.role === 'HOTEL_ADMIN') {
        //   localStorage.setItem('selectedBranchId', admin.branch.id);
        //   history.push(`/branch/${admin.branch.id}/dashboard`);
        // }
      })
      .then((tenantProfile) => {
        console.log('tenantProfile', tenantProfile);
        localStorage.setItem('tenantProfile', JSON.stringify(tenantProfile.data));
        updateState((previousState) => ({ ...previousState, tenantProfile: tenantProfile.data }));

        const adminBranch = data.user.roles.filter((role) => role.branch !== null);

        if (adminBranch.length === 0) {
          // history.replace('/tenant/dashboard');
          history.replace('/tenant/ecommerce/dashboard');
        } else if (adminBranch.length > 1) {
          history.replace('/tenant/dashboard');
        } else {
          localStorage.setItem('selectedBranchId', adminBranch[0].id);
          localStorage.setItem('selectedBranchName', adminBranch[0].name);
          history.push(`/branch/${adminBranch[0].id}/dashboard`);
        }
      })
      .catch((error) => {
        setSubmit(false);
        if (error) {
          setError(true);
        } else {
          setError(false);
        }
      });
  };

  const signOut = () => {
    localStorage.clear();
    setIdentity(null);
    history.replace('/');
  };

  useEffect(() => {
    async function loadUserData() {
      try {
        const adminToken = localStorage.getItem('token');
        const adminProfile: UserDto = localStorage.getItem('uat') && JSON.parse(localStorage.getItem('uat'));
        if (adminToken && adminProfile) {
          setIdentity(adminProfile);
          setToken(adminToken);
        } else {
          setToken(null);
          setIdentity(null);
          history.replace('/');
        }
      } catch (error) {
        addError(error);
        localStorage.clear();
      } finally {
        setLoading(false);
      }
    }

    loadUserData();
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <AuthContext.Provider value={{ identity, signIn, signOut, isLoading, token, isSubmitted, isError, tenantProfile }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => React.useContext(AuthContext);
