import React from 'react';
import { Layout, Spin, Row } from 'antd';
import { Switch, Route, useLocation, useHistory, withRouter } from 'react-router-dom';
import { globalRoutes } from './routes';
import { AppRoutes } from '@libs/dto';
import { useAuth } from '@context/auth-context';
import { useApp } from '@context/app-context';
import './App.scss';

const Login = React.lazy(() => import('@pages/Login'));

const Spinner = () => (
  <Layout style={{ placeContent: 'center', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <Row align="middle" justify="center">
      <Spin size="large" />
    </Row>
  </Layout>
);

function App() {
  const { identity } = useAuth();
  const { selectedBranchId, selectBranch } = useApp();
  const routes: AppRoutes = globalRoutes;

  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    if (location.pathname.search('null') !== -1 && selectedBranchId) {
      history.push(`/branch/${selectedBranchId}/dashboard`);
    } else if (location.pathname.search('null') !== -1 && !selectedBranchId) {
      history.push(`/tenant/dashboard`);
    }
  }, [location, history, selectedBranchId]);

  React.useEffect(() => {
    if (!location.pathname.includes('/branch/')) {
      selectBranch(null, null);
    } else if (location.pathname.includes('/branch/') && !selectedBranchId) {
      selectBranch(null, null);
      history.push(`/tenant/dashboard`);
    }
    // eslint-disable-next-line
  }, [location]);

  return (
    <>
      {identity ? (
        <React.Suspense fallback={<Spinner />}>
          <Switch>
            {routes.map((route) => (
              <Route {...route} key={route.id} />
            ))}
          </Switch>
        </React.Suspense>
      ) : (
        <React.Suspense fallback={<Spinner />}>
          <Login />
        </React.Suspense>
      )}
    </>
  );
}

export default withRouter(App);
