import React from 'react';
import { AppRoutes } from '@libs/dto';
import { cms } from './routes.config';

const Cms = React.lazy(() => import('@modules/cms/pages'));
const CmsMenuList = React.lazy(() => import('@modules/cms/pages/Menus/List'));
const CmsArticleList = React.lazy(() => import('@modules/cms/pages/Articles/List'));
const CmsArticleAdd = React.lazy(() => import('@modules/cms/pages/Articles/Add'));
const CmsArticleEdit = React.lazy(() => import('@modules/cms/pages/Articles/Edit'));
const CmsCategories = React.lazy(() => import('@modules/cms/pages/Categories'));
const CmsTags = React.lazy(() => import('@modules/cms/pages/Tags'));
const CmsBanners = React.lazy(() => import('@modules/cms/pages/Banners'));
const CmsPopUp = React.lazy(() => import('@modules/cms/pages/PopUp'));
const CmsB2B = React.lazy(() => import('@modules/cms/pages/B2B'));

export const routes: AppRoutes = [
  {
    id: 1,
    path: `${cms}`,
    component: Cms,
    exact: true,
  },
  {
    id: 2,
    path: `${cms}/articles`,
    component: CmsArticleList,
    exact: true,
  },
  {
    id: 3,
    path: `${cms}/menus`,
    component: CmsMenuList,
    exact: true,
  },
  {
    id: 4,
    path: `${cms}/articles/add`,
    component: CmsArticleAdd,
    exact: true,
  },
  {
    id: 5,
    path: `${cms}/articles/:id/edit`,
    component: CmsArticleEdit,
    exact: true,
  },
  {
    id: 6,
    path: `${cms}/categories`,
    component: CmsCategories,
    exact: true,
  },
  {
    id: 7,
    path: `${cms}/tags`,
    component: CmsTags,
    exact: true,
  },
  {
    id: 8,
    path: `${cms}/banners`,
    component: CmsBanners,
    exact: true,
  },
  {
    id: 9,
    path: `${cms}/popup`,
    component: CmsPopUp,
    exact: true,
  },
  {
    id: 10,
    path: `${cms}/b2b`,
    component: CmsB2B,
    exact: true,
  },
];
