import AppContextProvider from '@context/app-context';
import AuthContextProvider from '@context/auth-context';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

if (process.env.REACT_APP_SENTRY === 'true') {
  Sentry.init({
    dsn: 'https://ff182d2d27f1438280bf687b9cbebddc@o456785.ingest.sentry.io/5457775',
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

// (window as any).less
//   .modifyVars({
//     '@primary-color': 'rgba(255, 99, 20, 1)',
//   })
//   .then(() => {
//     //do other stuff here
//   })
//   .catch((error) => {
//     console.error(error);
//   });

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthContextProvider>
        <AppContextProvider>
          <App />
        </AppContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
