import React from 'react';
import { AppRoutes } from '@libs/dto';

import { branch } from './routes.config';

import {
  RoomRoutes,
  // FoodRoutes,
  // SouvenirRoutes,
  EcommerceRoutes,
} from './module/index';

// const DashboardAdmin = React.lazy(() => import('./pages/Dashboard'));
const AdminBranchSetting = React.lazy(() => import('./pages/Settings'));

export const routes: AppRoutes = [
  {
    id: 1,
    path: `${branch}/:id/settings`,
    component: AdminBranchSetting,
    exact: true,
  },
  // {
  //   id: 2,
  //   path: `${branch}/:id/dashboard`,
  //   component: DashboardAdmin,
  //   exact: true,
  // },
  ...RoomRoutes,
  // ...FoodRoutes,
  // ...SouvenirRoutes,
  ...EcommerceRoutes,
];
