import React from 'react';
import { AppRoutes } from '@libs/dto';
import { ecommerce } from './routes.config';

const FoodDashboard = React.lazy(() => import('@modules/ecommerce/pages/Dashboard'));
const FoodList = React.lazy(() => import('@modules/ecommerce/pages/Setting/Products'));
const FoodCategories = React.lazy(() => import('@modules/ecommerce/pages/Setting/Categories'));
const FoodAdd = React.lazy(() => import('@modules/ecommerce/pages/Setting/Products/Add'));
const FoodEdit = React.lazy(() => import('@modules/ecommerce/pages/Setting/Products/Edit'));
const ProductRegistartion = React.lazy(() => import('@modules/ecommerce/pages/Setting/ProductRegistration/List'));
const ProductRegistartionAdd = React.lazy(() => import('@modules/ecommerce/pages/Setting/ProductRegistration/Add'));
const ProductRegistartionEdit = React.lazy(() => import('@modules/ecommerce/pages/Setting/ProductRegistration/Edit'));
const ProductRegistartionDetail = React.lazy(
  () => import('@modules/ecommerce/pages/Setting/ProductRegistration/Detail')
);

const FoodOrderList = React.lazy(() => import('@modules/ecommerce/pages/Orders/List'));
const FoodOrderDetail = React.lazy(() => import('@modules/ecommerce/pages/Orders/Detail'));
const FoodOrderAdd = React.lazy(() => import('@modules/ecommerce/pages/Orders/Add'));
const FoodOrderEdit = React.lazy(() => import('@modules/ecommerce/pages/Orders/Edit'));
const PaymentConfigure = React.lazy(() => import('@modules/ecommerce/pages/Setting/Payment/Gateway/Configure'));
const PaymentChannelConfigure = React.lazy(() => import('@modules/ecommerce/pages/Setting/Payment/Channel/Configure'));
const ShipmentConfigure = React.lazy(() => import('@modules/ecommerce/pages/Setting/Shipping/Gateway/Configure'));

const InventoryList = React.lazy(() => import('@modules/ecommerce/pages/Setting/Inventory'));
const AddLocation = React.lazy(() => import('@modules/ecommerce/pages/Setting/Location/Add'));
const EditLocation = React.lazy(() => import('@modules/ecommerce/pages/Setting/Location/Edit'));

const VoucherList = React.lazy(() => import('@modules/ecommerce/pages/Vouchers/List'));
const VoucherAdd = React.lazy(() => import('@modules/ecommerce/pages/Vouchers/Add'));
const VoucherEdit = React.lazy(() => import('@modules/ecommerce/pages/Vouchers/Edit'));
const VoucherBulk = React.lazy(() => import('@modules/ecommerce/pages/Vouchers/Bulk'));

const ReviewsList = React.lazy(() => import('@modules/ecommerce/pages/Reviews'));

const ShippingChannelConfigure = React.lazy(
  () => import('@modules/ecommerce/pages/Setting/Shipping/Channel/Configure')
);

export const routes: AppRoutes = [
  {
    id: 19,
    path: `${ecommerce}/dashboard`,
    component: FoodDashboard,
    exact: true,
  },
  {
    id: 20,
    path: `${ecommerce}/orders`,
    component: FoodOrderList,
    exact: true,
  },
  {
    id: 21,
    path: `${ecommerce}/products`,
    component: FoodList,
    exact: true,
  },
  {
    id: 22,
    path: `${ecommerce}/products/categories`,
    component: FoodCategories,
    exact: true,
  },
  {
    id: 23,
    path: `${ecommerce}/products/add`,
    component: FoodAdd,
    exact: true,
  },
  {
    id: 24,
    path: `${ecommerce}/products/:product_id/edit`,
    component: FoodEdit,
  },
  {
    id: 25,
    path: `${ecommerce}/orders/:ecommerce_id/detail`,
    component: FoodOrderDetail,
    exact: true,
  },
  {
    id: 26,
    path: `${ecommerce}/payment-gateways/:name`,
    component: PaymentConfigure,
    exact: true,
  },
  {
    id: 27,
    path: `${ecommerce}/payment-gateways/:name/payment-channels/:channel`,
    component: PaymentChannelConfigure,
    exact: true,
  },
  {
    id: 28,
    path: `${ecommerce}/shipping-gateways/:name`,
    component: ShipmentConfigure,
    exact: true,
  },
  {
    id: 29,
    path: `${ecommerce}/orders/add`,
    component: FoodOrderAdd,
    exact: true,
  },
  {
    id: 30,
    path: `${ecommerce}/inventory`,
    component: InventoryList,
  },
  {
    id: 31,
    path: `${ecommerce}/locations/add`,
    component: AddLocation,
    exact: true,
  },
  {
    id: 32,
    path: `${ecommerce}/locations/:id/edit`,
    component: EditLocation,
    exact: true,
  },
  {
    id: 33,
    path: `${ecommerce}/orders/:ecommerce_id/edit`,
    component: FoodOrderEdit,
    exact: true,
  },
  {
    id: 34,
    path: `${ecommerce}/marketing/vouchers`,
    component: VoucherList,
    exact: true,
  },
  {
    id: 35,
    path: `${ecommerce}/marketing/vouchers/add`,
    component: VoucherAdd,
    exact: true,
  },
  {
    id: 36,
    path: `${ecommerce}/marketing/vouchers/:voucher_id/edit`,
    component: VoucherEdit,
    exact: true,
  },
  {
    id: 37,
    path: `${ecommerce}/reviews`,
    component: ReviewsList,
    exact: true,
  },
  {
    id: 38,
    path: `${ecommerce}/products-registration`,
    component: ProductRegistartion,
    exact: true,
  },
  {
    id: 39,
    path: `${ecommerce}/products-registration/add`,
    component: ProductRegistartionAdd,
    exact: true,
  },
  {
    id: 40,
    path: `${ecommerce}/products-registration/:id/edit`,
    component: ProductRegistartionEdit,
    exact: true,
  },
  {
    id: 41,
    path: `${ecommerce}/products-registration/:id`,
    component: ProductRegistartionDetail,
    exact: true,
  },
  {
    id: 42,
    path: `${ecommerce}/marketing/vouchers/bulk`,
    component: VoucherBulk,
    exact: true,
  },
  {
    id: 43,
    path: `${ecommerce}/shipping-gateways/:name/shipping-channels/:channel`,
    component: ShippingChannelConfigure,
    exact: true,
  },
];
