import { axiosInstance } from '@config/axios-instance';
import { AddCityDto, UpdateCityDto } from '@libs/dto';

const url = '/admin/settings/cities';

export const cityService = {
  list({ page = '1', per_page = '10' }: { page: string; per_page: string }) {
    return axiosInstance.get(`${url}`, {
      params: {
        page,
        per_page,
      },
    });
  },
  get(id: string) {
    return axiosInstance.get(`${url}/${id}`);
  },
  create(data: AddCityDto) {
    return axiosInstance.post(`${url}`, data);
  },
  update(id: string, data: UpdateCityDto) {
    return axiosInstance.put(`${url}/${id}`, data);
  },
  delete(id: string) {
    return axiosInstance.delete(`${url}/${id}`);
  },
};

export type CityService = typeof cityService;
